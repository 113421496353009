<template>
  <div
    class="flex justify-between items-center w-full"
    :class="{ 'flex-row-reverse': reverse }"
  >
    <div class="flex flex-col" v-if="label || labelDescription">
      <div
        class="font-medium leading-5 text-sm text-promy-grey-100 first-letter:uppercase"
        v-if="label"
      >
        {{ label }}
      </div>
      <div class="leading-5 text-sm text-promy-grey-70" v-if="labelDescription">
        {{ labelDescription }}
      </div>
    </div>
    <span
      class="toggle-wrapper inline-block relative cursor-pointer w-10 h-6 rounded-full;"
      :class="{ 'mr-2': reverse, 'ml-2': !reverse }"
      role="checkbox"
      :aria-checked="toggleValue.toString()"
      tabindex="0"
      @click="toggle"
      @keydown.space.prevent="toggle"
    >
      <span
        class="toggle-background inline-block rounded-full w-full h-full"
        :class="backgroundStyles"
      />
      <span
        class="toggle-indicator absolute h-5 w-5 bg-white rounded-full"
        :style="indicatorStyles"
      />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    isSet: {
      type: Boolean,
      default: false,
    },
    modelValue: [Boolean, Array],
    val: [String, Object],
    label: {
      type: String,
      required: false,
    },
    labelDescription: {
      type: String,
      required: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.toggleValue = !this.toggleValue
      this.$emit('change', this.val)
    },
  },
  data() {
    return {
      toggleValue: false,
    }
  },
  watch: {
    isSet: {
      immediate: true,
      handler(val) {
        this.toggleValue = val
      },
    },
  },
  computed: {
    indicatorStyles() {
      return {
        transform: this.toggleValue ? 'translateX(16px)' : 'translateX(0)',
      }
    },
    backgroundStyles() {
      return {
        'gold-mid': this.toggleValue,
        'gray-lighter': !this.toggleValue,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.gold-mid {
  @apply bg-promy-primary-90;

  &:hover {
    @apply bg-promy-primary-100;
  }
}

.gray-lighter {
  @apply bg-promy-grey-40;

  &:hover {
    @apply bg-promy-grey-50;
  }
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.4s ease;
}

.toggle-indicator {
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease;
}
</style>
