<template>
  <div class="rounded h-32 bg-white px-6 py-5">
    <div class="flex justify-between items-stretch">
      <label for="cadastre" class="text-gray-600 text-sm capitalize">
        Cadastre
      </label>
      <div class="mr-8 relative">
        <input
          type="checkbox"
          id="cadastre"
          v-model="is_cadastre_officiel"
          :value="true"
        />
        <label for="cadastre"></label>
      </div>
    </div>
    <div class="mt-4">
      <span class="text-gray-500 text-xs">Opacité</span>
      <vue-slider
        dotSize="12"
        :railStyle="{ backgroundColor: '#ECECEC' }"
        :dotStyle="{ backgroundColor: '#865af8' }"
        :process-style="{ backgroundColor: '#B09AE9' }"
        v-model="opacity_layers_cadastre"
      />
    </div>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { mapGetters } from 'vuex'
import axios from 'axios'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'
import mapbox from '@/mixins/mapbox'
import helpers from '@/mixins/helpers'
var convert = require('xml-js')

export default {
  mixins: [helpers, mapbox, sourcesAndLayers],
  components: {
    VueSlider,
  },
  data() {
    return {
      is_cadastre_officiel: false,
      opacity_layers_cadastre: 100,
    }
  },

  props: {
    map: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      infos_cadastre: 'parcelle/infos_cadastre',
      wms_list_layers_names: 'cadastreOfficiel/wms_list_layers_names',
      error_wms_cadastre_officiel:
        'cadastreOfficiel/error_wms_cadastre_officiel',
      infos_commune: 'parcelle/infos_commune',
    }),
  },
  created() {
    this.GetCapabilitiesCadastre()
  },
  methods: {
    GetCapabilitiesCadastre() {
      if (
        !this.error_wms_cadastre_officiel &&
        this.wms_list_layers_names.length === 0
      ) {
        axios
          .get(
            'https://inspire.cadastre.gouv.fr/scpc/' +
              this.infos_commune.code_insee +
              '.wms?service=WMS&request=GetCapabilities',
          )
          .then((response) => {
            let xmlDoc = response.data
            let data = convert.xml2json(xmlDoc, {
              compact: true,
              spaces: 4,
            })
            let list_layers =
              JSON.parse(data).WMS_Capabilities.Capability.Layer.Layer
            this.$store.commit(
              'cadastreOfficiel/WMS_LIST_LAYERS_NAMES',
              list_layers.map((layer) => layer.Name._text),
            )
          })
          .catch((e) => {
            this.$store.commit(
              'cadastreOfficiel/ERROR_WMS_CADASTRE_OFFICIEL',
              true,
            )
          })
      }
    },
    addSourceWmsCadastreOfficiel() {
      this.map.addSource(this.source_and_layers_cadastre[0], {
        type: 'raster',
        tiles: [
          'https://inspire.cadastre.gouv.fr/scpc/' +
            this.infos_commune.code_insee +
            '.wms?service=wms&version=1.3&request=GetMap&layers=' +
            this.wms_list_layers_names.toString() +
            '&format=image/png&crs=EPSG:3857&BBOX={bbox-epsg-3857}&width=256&height=256&styles&transparent=true',
        ],
      })
    },
    addLayerWmsCadastreOfficiel() {
      this.map.addLayer({
        id: this.source_and_layers_cadastre[1],
        type: 'raster',
        source: this.source_and_layers_cadastre[0],
        paint: {},
      })
    },
    setOpacityLayerCadastreOfficiel(opacity) {
      if (this.isExist(this.map.getLayer(this.source_and_layers_cadastre[1]))) {
        this.map.setPaintProperty(
          this.source_and_layers_cadastre[1],
          'raster-opacity',
          parseInt(opacity, 10) / 100,
        )
      }
    },
  },
  watch: {
    is_cadastre_officiel(newValue) {
      if (!this.error_wms_cadastre_officiel && this.map) {
        if (newValue) {
          if (
            !this.isExist(this.map.getLayer(this.source_and_layers_cadastre[1]))
          ) {
            this.addSourceWmsCadastreOfficiel()
            this.addLayerWmsCadastreOfficiel()
          }

          this.map.setLayoutProperty(
            this.source_and_layers_cadastre[1],
            'visibility',
            'visible',
          )
        } else {
          if (
            this.isExist(this.map.getLayer(this.source_and_layers_cadastre[1]))
          )
            this.map.setLayoutProperty(
              this.source_and_layers_cadastre[1],
              'visibility',
              'none',
            )
        }
      }
    },
    opacity_layers_cadastre(newValue) {
      this.setOpacityLayerCadastreOfficiel(newValue)
    },
  },
}
</script>
