import transport from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    poi: {},
    poi_is_loading: true,
    types: [
      {
        label: 'Transports',
        keys: ['transport', 'transport_en_commun'],
        marker: 'transport',
        color: '#1578fe',
        subKeys: [
          {
            name: 'Bus',
            key: 'bus',
            marker: 'bus',
          },
          {
            name: 'Accès routier',
            key: 'route_rapide',
            marker: 'transport',
          },
          {
            name: 'Gare',
            key: 'gare',
            marker: 'gare',
          },
          {
            name: 'Parking',
            key: 'parking',
            marker: 'parking',
          },
          {
            name: 'Aéroport',
            key: 'aeroport',
            marker: 'airport',
          },
        ],
      },
      {
        label: 'Éducation',
        keys: ['education'],
        marker: 'education',
        color: '#69d8fe',
        subKeys: [
          {
            name: 'Crèche',
            key: 'creche',
            marker: 'creche',
          },
          {
            name: 'Maternelle',
            key: 'maternelle',
            marker: 'maternelle',
          },
          {
            name: 'Élémentaire',
            key: 'elementaire',
            marker: 'maternelle',
          },
          {
            name: 'Collège',
            key: 'college',
            marker: 'maternelle',
          },
          {
            name: 'Lycée',
            key: 'lycee',
            marker: 'maternelle',
          },
        ],
      },
      {
        label: 'Commerce',
        keys: ['publique'],
        marker: 'commerce',
        color: '#ed8f12',
        subKeys: [
          {
            name: 'Supermarché',
            key: 'alimentation_generale',
            marker: 'commerce',
          },
          {
            name: 'Shopping',
            key: 'shopping',
            marker: 'shopping',
          },
        ],
      },
      {
        label: 'Loisir et tourisme',
        keys: ['publique'],
        marker: 'commerce',
        color: '#5c40a5',
        subKeys: [
          {
            name: 'Cinema',
            key: 'cinema',
            marker: 'cinema',
          },
          {
            name: 'Bar et café',
            key: 'bar_et_cafe',
            marker: 'barEtCafe',
          },
          {
            name: 'Restaurant',
            key: 'restaurant',
            marker: 'restaurant',
          },
          {
            name: 'Clubs',
            key: 'clubs',
            marker: 'club',
          },
          {
            name: 'Musée',
            key: 'musee',
            marker: 'musee',
          },
          {
            name: 'Theatre',
            key: 'theatre/opera',
            marker: 'theatre',
          },
          {
            name: 'Sport',
            key: 'sport_individuel',
            marker: 'sport',
          },
          {
            name: 'Sport collectif',
            key: 'sport_collectif',
            marker: 'sportCollectif',
          },
          {
            name: 'Hotel',
            key: 'hotel',
            marker: 'hotel',
          },
        ],
      },
      {
        label: 'Services publics et Santé',
        keys: ['publique'],
        marker: 'sante',
        color: '#7ed859',
        subKeys: [
          {
            name: 'Commissariat',
            key: 'commissariat',
            marker: 'securiteGreen',
          },
          {
            name: 'Gendarmerie',
            key: 'gendarmerie',
            marker: 'securiteGreen',
          },
          {
            name: 'Hôpital',
            key: 'urgences',
            marker: 'hospital',
          },
          {
            name: 'Pompiers',
            key: 'pompiers',
            marker: 'pompier',
          },
          {
            name: 'Médecin(s)',
            key: 'medecin',
            marker: 'doctor',
          },
          {
            name: 'Pharmacie',
            key: 'pharmacie',
            marker: 'pharmacy',
          },
          {
            name: 'personnes âgées',
            key: 'personnes_agees',
            marker: 'pharmacy',
          },
          {
            name: 'postes',
            key: 'postes',
            marker: 'pharmacy',
          },
        ],
      },
    ],
  },
  getters: {
    poi(state) {
      return state.poi
    },
    poi_is_loading(state) {
      return state.poi_is_loading
    },
    types(state) {
      return state.types
    },
  },
  mutations: {
    POI(state, payload) {
      state.poi = Object.freeze(payload)
    },
    POI_IS_LOADING(state, payload) {
      state.poi_is_loading = payload
    },
  },
  actions: {
    getPoi({ commit }, params) {
      return new Promise((resolve, reject) => {
        transport
          .get(`address`, {
            params: {
              lon: params[0],
              lat: params[1],
              type: 'poi',
            },
          })
          .then((response) => {
            commit('POI', response.data.poi)
            commit('POI_IS_LOADING', false)
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
