import isEqual from 'lodash/isEqual'
export default {
  data() {
    return {
      palettes_color: [
        '#356390',
        '#00b7ab',
        '#FFB624',
        '#414141',
        '#321E6A',
        '#E53636',
        '#B09AE9',
        '#0BE096',
        '#ACACAC',
        '#222222',
      ],
    }
  },
  methods: {
    removeDuplicateFeature(features) {
      let remove_index_feature = []
      features.forEach((feature, index) => {
        features.forEach((temp_feature, child_index) => {
          if (
            isEqual(
              feature.geometry.coordinates,
              temp_feature.geometry.coordinates,
            ) &&
            index !== child_index &&
            remove_index_feature.indexOf(index) === -1
          ) {
            remove_index_feature.push(child_index)
          }
        })
      })
      features = features.filter((item, index) => {
        return remove_index_feature.indexOf(index) === -1
      })
      return features
    },
    getNestedObject(obj, ...args) {
      return args.reduce((obj, level) => obj && obj[level], obj)
    },
    isExist(valeur) {
      return typeof valeur !== 'undefined' && valeur !== null
    },
    copyObject(obj) {
      return Object.assign({}, obj)
    },
    getCoordinates(coordinates) {
      var new_coordinates = []
      coordinates.forEach(function iter(element) {
        if (Array.isArray(element[0][0])) {
          element.forEach(iter)
        } else {
          new_coordinates.push(element)
          return
        }
      })
      return new_coordinates
    },
    round(value) {
      return Math.round(value)
    },
    templatePopup(
      title,
      descriptions,
      with_link = false,
      is_list_order = false,
    ) {
      let template = ` <div class=' flex flex-col w-62 break-words bg-white rounded-box  sm:w-50 '> <div class=' px-5 rounded-t-box flex w-full h-9 bg-promy-green-300 sm:pl-3 sm:pr-5'>
        <span 
        class = 'text-base text-white my-2 font-extrabold font-main sm:break-all sm:text-sm '
        >
        ${this.truncateText(title.join(' , '), 25)}
        </span> </div> <div class = ' flex flex-col flex-1' > <div class = 'flex w-full flex-1 items-center font-bold py-2' > <span class = 'overflow-y-auto pl-5 pr-8 w-full mr-2'
        style = 'max-height:82px;'> `
      descriptions.forEach((description, index) => {
        if (index > 0 && index < descriptions.length) {
          template += `<hr class='h-1/2 bg-promy-gray-300 border-0 my-1'>`
        }
        if (descriptions.length > 1 && is_list_order)
          template += `<span class='text-promy-purple-400'>${
            index + 1
          })  </span> `
        template += description
      })
      template += `</span> </div>`
      if (with_link) {
        template += `<div class=' pl-5 flex w-full pb-4'> <span class=' text-base font-extrabold font-main text-promy-green-200 cursor-pointer' id='docurba'> DOCUMENT URBANISME </span>  </div> `
      }
      template += `</div>  </div>`
      return template
    },
    parseStringtoArray(value) {
      return this.uniqueValues([...JSON.parse(value)])
    },
    uniqueValues(value) {
      return [...new Set(value)]
    },
    truncateText(text, length, suffix = '...') {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
    scrollTo(element, to, duration) {
      let vm = this
      var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20

      var animateScroll = function () {
        currentTime += increment
        var val = vm.easeInOutQuad(currentTime, start, change, duration)
        element.scrollTop = val
        if (currentTime < duration) {
          setTimeout(animateScroll, increment)
        }
      }
      animateScroll()
    },
    easeInOutQuad(t, b, c, d) {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t + b
      t--
      return (-c / 2) * (t * (t - 2) - 1) + b
    },
    isEmptyOrNull(data) {
      return data === '' || data === null || data === undefined
    },
    countdownTimer(id, endAt) {
      let countDownDate = new Date(endAt).getTime()

      let x = setInterval(() => {
        let now = new Date().getTime()
        let distance = countDownDate - now
        let days = Math.floor(distance / (1000 * 60 * 60 * 24))
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        )
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        let seconds = Math.floor((distance % (1000 * 60)) / 1000)
        if (document.getElementById(id) == null) {
          clearInterval(x)
        } else {
          document.getElementById(id).innerHTML =
            days + 'j ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
        }
      }, 1000)
    },
  },
}
