import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './plugins'
import './filters'
import './utilities'
import _ from 'lodash'

Vue.prototype.$base_file = process.env.VUE_APP_BASE_STORAGE
Vue.prototype.$mapbox_directions = process.env.VUE_APP_MAPBOX_DIRECTIONS_API
Vue.prototype.$mapbox_elevations = process.env.VUE_APP_MAPBOX_ELEVATIONS_API
Vue.prototype.$cadastre_etalab_geo_promy =
  process.env.VUE_APP_CADASTRE_ETALAB_GEO_PROMY

Vue.prototype.$domain_urbanisme_promy = process.env.VUE_APP_UBANISME_PROMY

Vue.config.productionTip = false

store.dispatch('auth/me').then(() => {
  new Vue({
    render: (h) => h(App),
    router,
    store,
  }).$mount('#app')
})
