function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
const defaultLayout = () =>
  import(/* webpackChunkName: '' */ '../../layouts/default')

import auth from '../../middleware/auth'

export default [
  {
    path: 'dossiers-promoteurs',
    name: 'dossiers-promoteurs',
    meta: {
      middleware: [auth],
      hasSidebar: true,
    },
    component: page('DossierPromoteurs/Main'),
    redirect: 'dossiers-promoteurs/list',
    children: [
      {
        path: 'list',
        name: 'list-dossier-promoteur',
        component: page('DossierPromoteurs/List'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: ':id/vues',
        name: 'dossier-promoteur-vues',
        component: page('DossierPromoteurs/Vues'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: ':id/offres',
        name: 'dossier-promoteur-offres',
        component: page('DossierPromoteurs/Offres'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'dossier-promoteurs/:dossier_promoteur_id/offres/:offre_id',
        name: 'dossier-promoteur-offres-offre',
        component: page('DossierPromoteurs/Offres/offre'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
          is_redirect_url: true,
        },
      },
      {
        path: 'synthese',
        component: page('dashboard/Parametrage/Synthese'),
        meta: {
          middleware: [auth],
        },
        name: 'synthese-component',
        redirect: 'synthese/edit',
        children: [
          {
            path: 'edit',
            name: 'synthese',
            component: page('dashboard/Parametrage/Synthese/edit'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'form',
            name: 'synthese-form',
            component: page('dashboard/Parametrage/Synthese/form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: ':id/edit',
            name: 'synthese-edit-form',
            meta: {
              mode: 'edit',
              middleware: [auth],
            },
            component: page('dashboard/Parametrage/Synthese/form'),
          },
        ],
      },
      {
        path: 'conditions-suspensives',
        name: 'conditions-suspensives',
        component: page('dashboard/Parametrage/ConditionsSuspensives'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'questions-matching',
        name: 'questions-matching',
        component: page('dashboard/Parametrage/QuestionsMatching'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'les-gammes',
        name: 'les-gammes',
        component: page('dashboard/Parametrage/Lesgammes'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'quelques-chiffres',
        name: 'quelques-chiffres',
        component: page('dashboard/Parametrage/QuelquesChiffres'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'contexte-general',
        name: 'contexte-general',
        component: page('dashboard/Parametrage/ContextGeneral'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'timeline',
        component: page('dashboard/Parametrage/Timeline'),
        meta: {
          middleware: [auth],
        },
        name: 'timeline-component',
        redirect: 'timeline',
        children: [
          {
            path: '',
            name: 'timeline',
            component: page('dashboard/Parametrage/Timeline/edit'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'form',
            name: 'timeline-form',
            component: page('dashboard/Parametrage/Timeline/form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: ':id/edit',
            name: 'timeline-edit-form',
            meta: {
              mode: 'edit',
              middleware: [auth],
            },
            component: page('dashboard/Parametrage/Timeline/form'),
          },
        ],
      },
      {
        path: 'informations',
        name: 'parametrage-informations',
        component: page('dashboard/Parametrage/Informations'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'projet-by-promy',
        name: 'projet-by-promy',
        component: page('dashboard/Parametrage/ProjetByPromy'),
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: 'dossier-promoteurs',
    name: 'dossier-promoteurs',
    meta: {
      middleware: [auth],
      hasSidebar: true,
    },
    component: page('DossierPromoteurs'),
    redirect: 'dossier-promoteurs/Informations',
    children: [
      {
        path: 'informations/:id?',
        name: 'informations',
        component: page('DossierPromoteurs/Informations'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'commune/:id?',
        name: 'commune',
        component: page('DossierPromoteurs/Commune'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'synthese-du-marche/:id?',
        name: 'synthese-du-marche',
        component: page('DossierPromoteurs/SyntheseMarche'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'contexte-generale/:id?',
        name: 'contexte-generale',
        component: page('DossierPromoteurs/ContexteGenerale'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'etude-commerciale/:id?',
        name: 'etude-commerciale',
        component: page('DossierPromoteurs/Etude'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },

      {
        path: 'urbanisme/:id?',
        name: 'urbanisme',
        component: page('DossierPromoteurs/Urbanisme'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'localisation/:id?',
        name: 'localisation',
        component: page('DossierPromoteurs/Localisation'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'le-projet-by-promy/:id?',
        name: 'le-projet-by-promy',
        component: page('DossierPromoteurs/ProjetByPromy'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'descriptif-projet/:id?',
        name: 'descriptif-projet',
        component: page('DossierPromoteurs/DescriptifProjet'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'rapport-technique/:id?',
        name: 'rapport-technique',
        component: page('DossierPromoteurs/RapportTechnique'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'bilan-financier/:id?',
        name: 'bilan-financier',
        component: page('DossierPromoteurs/BilanFinancier'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
      {
        path: 'dossier-photo/:id?',
        name: 'dossier-photo',
        component: page('DossierPromoteurs/DossierPhoto'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
    ],
  },
]
