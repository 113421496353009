function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'

export default [
  {
    name: 'partenaires',
    path: 'partenaires',
    meta: {
      middleware: [auth],
    },
    component: page('dashboard/Partenaires'),
    redirect: 'partenaires/promy',
    children: [
      {
        path: '',
        component: page('dashboard/Parametrage/Partenaire'),
        meta: {
          middleware: [auth],
        },
        name: '',
        redirect: 'promy',
        children: [
          {
            path: 'ajouter',
            name: 'add-partenaire',
            component: page('dashboard/Parametrage/Partenaire/Form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'modifier/:id',
            name: 'update-partenaire',
            component: page('dashboard/Parametrage/Partenaire/Form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'promy',
            name: 'list-partenaire',
            component: page('dashboard/Parametrage/Partenaire/list'),
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: 'activity-sector',
        name: 'activity_sector',
        redirect: 'activity-sector',
        component: page('dashboard/Parametrage/SecteurActivite'),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: 'ajouter',
            name: 'add-activity-sector',
            component: page('dashboard/Parametrage/SecteurActivite/Form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'modifier/:id',
            name: 'update-activity-sector',
            component: page('dashboard/Parametrage/SecteurActivite/Form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'activity-sector',
            name: 'list-activity-sector',
            component: page('dashboard/Parametrage/SecteurActivite/List'),
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
    ],
  },
]
