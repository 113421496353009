<template>
  <div
    class="flex flex-col w-full rounded-box overflow-hidden shadow-box px-5 py-3 my-2"
  >
    <div class="text-promy-gray-550 font-semibold text-xs mb-4">
      {{ label }}
    </div>
    <div
      class="flex items-center pl-3 lg:flex-col lg:mt-4 sm:pl-0 sm-ws:flex-col sm-ws:mt-4"
      v-if="dataset.length"
    >
      <div class="relative w-1/2 lg:w-full">
        <canvas :id="chartId"></canvas>
        <div
          class="total absolute flex flex-col items-center leading-4 transform -translate-y-1/2 -translate-x-1/2"
          v-if="unit !== '%'"
        >
          <span class="font-bold text-lg text-promy-gray-750">
            {{ totalDataset }}
          </span>
          <span class="text-promy-xs text-promy-gray-525 font-bold">
            {{ unit }}
          </span>
        </div>
      </div>

      <div class="w-1/2 lg:w-full flex text-xs pl-7 lg:pl-0">
        <div class="w-full">
          <div
            class="w-full flex flex-row justify-start items-baseline font-bold text-xs text-left my-4"
            v-for="(item, index) in sorted_data"
            :key="index"
          >
            <span class="mx-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 13 13"
                class="cls-circle"
              >
                <circle
                  class="cls-1"
                  cx="6.5"
                  cy="6.5"
                  r="5"
                  :style="{ stroke: item.color }"
                />
              </svg>
            </span>
            <p class="flex-1" :style="{ color: item.color }">
              {{ item.legend }}
            </p>
            <p class="mx-6 flex justify-end">{{ item.percent }}%</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <span>NC</span>
    </div>
  </div>
</template>

<script>
import chart from '@/mixins/chart'

export default {
  data() {
    return {
      totalDataset: 0,
      sorted_colors: [],
      sorted_dataset: [],
      sorted_legend: [],
      sorted_data: [],
      colors: [
        '#0cacd3',
        '#0b78d6',
        '#4d2755',
        '#11ca9c',
        '#0be096',
        '#00c9bc',
        '#0cacd3',
        '#7f51fa',
        '#351686',
        '#d7e83f',
        '#c511aa',
        '#c51111',
        '#b4aa3f',
        '#e7a115',
        '#fc82f7',
      ],
    }
  },
  mixins: [chart],
  props: {
    chartId: {
      type: String,
    },
    label: {
      type: String,
    },
    dataset: {
      type: Array,
    },
    legends: {
      type: Array,
    },
    unit: {
      type: String,
    },
    borderColor: {
      type: Array,
    },
    suffixe: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.dataset.length) {
      this.sortedData()
      this.InitRoundedDoughnut(), this.renderPie()
    }
  },
  watch: {
    dataset: {
      handler(value) {
        if (value.length) {
          this.sortedData()
          this.InitRoundedDoughnut(), this.renderPie()
        }
      },
    },
  },
  methods: {
    compareValues(key, order = 'asc') {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          return 0
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return order === 'desc' ? comparison * -1 : comparison
      }
    },
    round(value) {
      return Math.round(value)
    },
    percent(value, total) {
      return Math.round((value / total) * 100)
    },
    sortedData() {
      let temp_data = []
      let reducer = (accumulator, currentValue) => accumulator + currentValue
      this.totalDataset = this.round(this.dataset.reduce(reducer))

      this.legends.forEach((legend, index) => {
        temp_data.push({
          color: this.colors[index],
          dataset: this.dataset[index],
          legend: legend,
          percent: this.percent(this.dataset[index], this.totalDataset),
        })
      })
      this.sorted_data = temp_data.sort(this.compareValues('dataset', 'desc'))
      this.sorted_dataset = this.sorted_data.map((item) => item.dataset)
      this.sorted_colors = this.sorted_data.map((item) => item.color)
      this.sorted_legend = this.sorted_data.map((item) => item.legend)
    },

    renderPie() {
      var doghnut1 = document.getElementById(this.chartId)
      new Chart(doghnut1, {
        type: 'RoundedDoughnut',
        data: {
          labels: this.sorted_legend,
          datasets: [
            {
              label: this.sorted_legend,
              data: this.sorted_dataset,
              backgroundColor: this.sorted_colors,
              borderColor: this.sorted_colors,
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          suffixe: this.suffixe,
          cutoutPercentage: 78,
          legend: {
            display: false,
            reverse: true,
          },
          animation: {
            animateRotate: true,
            animateScale: true,
          },
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.circle-icon {
  margin-right: 4px;
  min-width: 7px;
  height: 7px;
}

.cls-circle {
  fill: none;
  stroke-linejoin: round;
  stroke-width: 3px;
}

.total {
  top: 50%;
  left: 50%;
}
</style>
