function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}

import auth from '../../middleware/auth'

export default [
  {
    path: 'parametrage',
    name: 'Parametrage',
    component: page('dashboard/Parametrage'),
    meta: {
      middleware: [auth],
    },
    redirect: 'parametrage/actualite',
    children: [
      {
        path: 'actualite',
        component: page('dashboard/Parametrage/Actualite'),
        meta: {
          middleware: [auth],
        },
        name: 'actualite',
        redirect: 'actualite/departements',
        children: [
          {
            path: 'departements',
            name: 'actualite-departements',
            component: page('dashboard/Parametrage/Actualite/departements'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'edit/:id',
            name: 'actualite-edit',
            component: page('dashboard/Parametrage/Actualite/edit'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'form',
            name: 'actualite-form',
            component: page('dashboard/Parametrage/Actualite/form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: ':id/edit',
            name: 'actualite-edit-form',
            meta: {
              mode: 'edit',
              middleware: [auth],
            },
            component: page('dashboard/Parametrage/Actualite/form'),
          },
        ],
      },
      {
        path: 'CGU',
        component: page('dashboard/Parametrage/CGU'),
        meta: {
          middleware: [auth],
        },
        name: 'CGU',
        redirect: 'CGU/liste',
        children: [
          {
            path: 'liste',
            name: 'list-CGU',
            component: page('dashboard/Parametrage/CGU/list'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'ajouter',
            name: 'add-CGU',
            component: page('dashboard/Parametrage/CGU/Form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'modifier/:id',
            name: 'update-CGU',
            component: page('dashboard/Parametrage/CGU/Form'),
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: 'softwares',
        name: 'softwares',
        component: page('dashboard/Parametrage/Softwares'),
        meta: {
          middleware: [auth],
        },
      },
      {
        name: 'messages-platefrome-promoteurs',
        path: 'messages-platefrome-promoteurs',
        component: page('dashboard/Parametrage/MessagesPromoteurs'),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: '',
            name: 'messages-platefrome-promoteurs-list',
            component: page('dashboard/Parametrage/MessagesPromoteurs/List'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'creer',
            name: 'messages-platefrome-promoteurs-create',
            component: page('dashboard/Parametrage/MessagesPromoteurs/Form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'modifier/:id',
            name: 'messages-platefrome-promoteurs-edit',
            component: page('dashboard/Parametrage/MessagesPromoteurs/Form'),
            meta: {
              mode: 'edit',
              middleware: [auth],
            },
          },
        ],
      },
    ],
  },
]
