<template>
  <div
    :class="[
      `flex lg:flex-col items-center ${display} bg-white lg:rounded-lg px-3 lg:px-0`,
      isHeader ? 'is-header lg:hidden' : '',
      pulsedBorder && pulsedBorder.hasPulsedBorder
        ? `border border-${pulsedBorder.color} animate-pulse`
        : 'border-none',
    ]"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    display: {
      type: String,
      default: '',
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    pulsedBorder: {
      required: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.is-header {
  @apply bg-promy-gray-225 #{!important};
}
@screen lg {
  div > div {
    @apply border-b-2 border-promy-gray-100;
  }

  div:last-child {
    @apply border-b-0;
  }
}
</style>
