<template>
  <div>
    <input
      type="checkbox"
      :id="id"
      :value="val"
      v-model="checked"
      @change="check"
      :disabled="disabled"
    />
    <label :for="id" class="pl-8 pt-1">{{ label ? label : '' }}</label>
  </div>
</template>

<script>
export default {
  props: {
    val: {
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      proxy: false,
    }
  },
  methods: {
    check(e) {
      this.$emit('change', { value: this.val, is_active: this.proxy })
      this.$emit('input', this.proxy)
    },
  },
  computed: {
    checked: {
      get() {
        return this.val
      },
      set(value) {
        this.proxy = value
      },
    },
  },
}
</script>

<style lang="scss">
[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  display: none;
}
[type='checkbox']:checked + label,
[type='checkbox']:not(:checked) + label {
  position: relative;
  cursor: pointer;
  color: #273869;
}
div.disabled {
  [type='checkbox']:checked + label:before,
  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    border: 1px solid #e4e4e4;
    background: #e4e4e4;
  }
}
[type='checkbox']:checked + label:before,
[type='checkbox']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #00c9bc;
  background: white;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 0px;
}
[type='checkbox']:checked + label:after,
[type='checkbox']:not(:checked) + label:after {
  content: '';
  background: #00c9bc;
  height: 11px;
  width: 11px;
  position: absolute;
  top: 7px;
  left: 7px;
  border: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='checkbox']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>
