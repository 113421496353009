<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {
    active_first: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Accordion: {
        count: 1,
        active: null,
      },
    }
  },
  created() {
    if (this.active_first) this.Accordion.active = 1
  },
  provide() {
    return { Accordion: this.Accordion }
  },
}
</script>

<style scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
}
.item:last-child {
  border-bottom: none;
}
</style>
