import transport from '@/plugins/axios'
import _ from 'lodash'

const getDefaultState = () => {
  return {
    is_saving_or_updating_DP: false,
    create: true,
    is_anonymous: false,
    is_not_visible_when_vendu: false,
    currentStatus: 2,
    has_pdf: null,
    informations: {},
    commune: {},
    etude_commerciale: {},
    contexte_generale: {},
    allConditionSuspensive: [],
    conditions: [],
    urbanisme: {},
    projetbypromy: {},
    localisation: {},
    dossier_photos: [],
    vues_panoramique: [],
    dossier_fichiers: {},
    synthese_marche_immobilier: {},
    syntheseMarcheId: '',
    rapport_technique: {},
    descriptif_projet: {},
    total_progress: {
      progress_dossier: 0,
      progress_section: {
        informations: 0,
        commune: 0,
        contexte_general: 0,
        urbanisme: 0,
        synthese_du_marche: 0,
        le_projet_by_promy: 0,
        localisation: 0,
        etude_commerciale: 0,
        dossier_photo: 0,
        rapport_technique: 0,
        bilan_financier: 0,
        descriptif_projet: 0,
      },
    },
    active_sections: {
      informations: {
        is_active: true,
        name: 'informations',
        key_progress: 'informations',
      },
      commune: {
        is_active: true,
        name: 'Commune',
        key_progress: 'commune',
      },
      urbanisme: {
        is_active: true,
        name: 'Urbanisme',
        key_progress: 'urbanisme',
      },
      localisation: {
        is_active: true,
        name: 'Localisation',
        key_progress: 'localisation',
      },
      projetbypromy: {
        is_active: true,
        name: 'Le Projet By Promy',
        key_progress: 'le_projet_by_promy',
      },
      dossier_photos: {
        is_active: true,
        name: 'Dossier Photo',
        key_progress: 'dossier_photo',
      },
      bilan_financier: {
        is_active: true,
        name: 'Bilan Financier',
        key_progress: 'bilan_financier',
      },
      contexte_generale: {
        is_active: true,
        name: 'Contexte Général',
        key_progress: 'contexte_general',
      },
      etude_commerciale: {
        is_active: true,
        name: 'Etude Commerciale',
        key_progress: 'etude_commerciale',
      },
      rapport_technique: {
        is_active: true,
        name: 'Rapport Technique',
        key_progress: 'rapport_technique',
      },
      descriptif_projet: {
        is_active: true,
        name: 'Descriptif projet',
        key_progress: 'descriptif_projet',
      },
      synthese_marche_immobilier: {
        is_active: true,
        name: 'Synthese Du Marche',
        key_progress: 'synthese_du_marche',
      },
    },
    selected_responsable: null,
    shouldntSendMails: false,
    shouldntSendMailsDescription: '',
    description_send_mail: '',
    publishing: null,
    notes: null,
    numberSeen: {},
    dossier_promoteur_selected: [],
    default_gammes: [],
    default_projet_by_promy: null,
  }
}

const getLocalState = () => {
  return {
    list_admins: [],
    sort_list_terrains_by: null,
    sort_list_terrains_by_order: null,
  }
}

export default {
  namespaced: true,
  state: Object.assign(getLocalState(), getDefaultState()),
  getters: {
    getCurrentStatus(state) {
      return state.currentStatus
    },
    getinfos(state) {
      return state.informations
    },
    getCommune(state) {
      return state.commune
    },
    getContexteGenerale(state) {
      return state.contexte_generale
    },
    getTimeline(state) {
      return state.timeline
    },
    getConditionSuspensive(state) {
      return state.allConditionSuspensive
    },
    getConditions(state) {
      return state.conditions
    },
    getUrbanisme(state) {
      return state.urbanisme
    },
    getProgressSection(state) {
      return state.total_progress.progress_section
    },
    getProgressDossir(state) {
      return state.total_progress.progress_dossier
    },
    getEtudeCommerciale(state) {
      return state.etude_commerciale
    },
    getProjetByPromy(state) {
      return state.projetbypromy
    },
    getSyntheseMarcheId(state) {
      return state.syntheseMarcheId
    },
    getDossierPhotos(state) {
      return state.dossier_photos
    },
    getVuesPanoramique(state) {
      return state.vues_panoramique
    },
    getLocalisation(state) {
      return state.localisation
    },
    getSyntheseDuMarche(state) {
      return state.synthese_marche_immobilier
    },
    getRapportTechnique(state) {
      return state.rapport_technique
    },
    getIsSavingOrUpdatingDP(state) {
      return state.is_saving_or_updating_DP
    },
    getListAdmins: (state) => state.list_admins,
    getSelectedResponsable: (state) => state.selected_responsable,
    getShouldntSendMails: (state) => state.shouldntSendMails,
    getShouldntSendMailsDescription: (state) =>
      state.shouldntSendMailsDescription,
    getNotes: (state) => state.notes,
    getNumberSeen: (state) => state.numberSeen,
    getDossierPromoteurSelected: (state) => state.dossier_promoteur_selected,
    getSortListTerrainsBy: (state) => state.sort_list_terrains_by,
    getSortListTerrainsByOrder: (state) => state.sort_list_terrains_by_order,
    getDefaultGammes: (state) => state.default_gammes,
    getDefaultProjetByPromy: (state) => state.default_projet_by_promy,
    getShouldSendMailDescription: (state) => state.description_send_mail,
    getActiveSections: (state) => state.active_sections,
    getDescriptifProjet: (state) => state.descriptif_projet,
    getDossierFichiers: (state) => state.dossier_fichiers,
  },
  mutations: {
    SetDossierConditionSuspensive(state, payload) {
      state.conditions = payload
    },
    setConditionSuspensive(state, payload) {
      state.allConditionSuspensive = payload
    },
    setMode(state, payload) {
      state.create = payload
    },
    resetDossierPromoteur(state) {
      Object.assign(state, getDefaultState())
    },
    setDossierPromoteur(state, payload) {
      state = Object.assign({}, state, payload)
    },
    setInformations(state, payload) {
      state.informations = Object.assign({}, state.informations, payload)
    },
    setCommune(state, payload) {
      state.commune = Object.assign({}, state.commune, payload)
    },
    setContexteGenerale(state, payload) {
      state.contexte_generale = Object.assign(
        {},
        state.contexte_generale,
        payload,
      )
    },
    setTimeline(state, payload) {
      state.timeline = Object.assign({}, state.timeline, payload)
    },
    setUrbanisme(state, payload) {
      state.urbanisme = Object.assign({}, state.urbanisme, payload)
    },
    setIsAnonymous(state, payload) {
      state.is_anonymous = payload
    },
    setIsNotVisibleWhenVendu(state, payload) {
      state.is_not_visible_when_vendu = payload
    },
    setProgressSection(state, payload) {
      Object.keys(state.total_progress.progress_section).map((key) => {
        if (
          ['localisation', 'urbanisme'].includes(payload.section) &&
          state.is_anonymous &&
          key === payload.section
        ) {
          state.total_progress.progress_section[key] = 100
          return
        }
        if (key === payload.section)
          state.total_progress.progress_section[key] = payload.value
      })
    },
    setProgressDossier(state, payload) {
      state.total_progress.progress_dossier = payload
    },
    setActiveSections(state, payload) {
      state.active_sections = payload
    },
    setEtudeCommerciale(state, payload) {
      state.etude_commerciale = Object.assign(
        {},
        state.etude_commerciale,
        payload,
      )
    },
    setProjetByPromy(state, payload) {
      state.projetbypromy = Object.assign({}, state.projetbypromy, payload)
    },
    setDossierPhotos(state, payload) {
      state.dossier_photos = payload
    },
    setVuesPanoramique(state, payload) {
      state.vues_panoramique = payload
    },
    setLocalisation(state, payload) {
      state.localisation = Object.assign({}, state.localisation, payload)
    },
    setSyntheseDuMarcheId(state, payload) {
      state.syntheseMarcheId = payload
    },
    setSyntheseDuMarche(state, payload) {
      state.synthese_marche_immobilier = Object.assign(
        {},
        state.synthese_marche_immobilier,
        payload,
      )
    },
    setTotalProgress(state, payload) {
      state.total_progress = _.merge({}, state.total_progress, payload)
    },
    setRapportTechnique(state, payload) {
      state.rapport_technique = payload
    },
    setIsSavingOrUpdatingDP(state, payload) {
      state.is_saving_or_updating_DP = payload
    },
    setCesPBP(state, payload) {
      state.projetbypromy.descriptifProjet.reglementationurbanisme.ces = payload
    },
    setHauteurPBP(state, payload) {
      _.set(
        state,
        'projetbypromy.descriptifProjet.reglementationurbanisme.hauteur',
        payload,
      )
    },
    setImplLimitPBP(state, payload) {
      _.set(
        state,
        'projetbypromy.descriptifProjet.reglementationurbanisme.impl_limit',
        payload,
      )
      state.projetbypromy.descriptifProjet.reglementationurbanisme.impl_limit =
        payload
    },
    setImplVoiePBP(state, payload) {
      _.set(
        state,
        'projetbypromy.descriptifProjet.reglementationurbanisme.impl_voie',
        payload,
      )
      state.projetbypromy.descriptifProjet.reglementationurbanisme.impl_voie =
        payload
    },
    setImplBatiPBP(state, payload) {
      _.set(
        state,
        'projetbypromy.descriptifProjet.reglementationurbanisme.impl_bati',
        payload,
      )
      state.projetbypromy.descriptifProjet.reglementationurbanisme.impl_bati =
        payload
    },
    setCesUrbanisme(state, payload) {
      state.urbanisme.ces = payload
    },
    setHauteurUrbanisme(state, payload) {
      state.urbanisme.hauteur = payload
    },
    setImplLimitUrbanisme(state, payload) {
      state.urbanisme.impl_limite_separative = payload
    },
    setImplVoieUrbanisme(state, payload) {
      state.urbanisme.imple_vole = payload
    },
    setImplBatiUrbanisme(state, payload) {
      state.urbanisme.impl_batiment = payload
    },
    SET_LIST_ADMINS(state, payload) {
      state.list_admins = payload
    },
    SET_SELECTED_RESPONSABLE(state, payload) {
      state.selected_responsable = payload
    },
    SET_SHOULDNT_SEND_MAILS(state, payload) {
      state.shouldntSendMails = payload
    },
    SET_SHOULDNT_SEND_MAILS_DESCRIPTION(state, payload) {
      state.shouldntSendMailsDescription = payload
    },
    SET_PUBLISHING(state, payload) {
      state.publishing = payload
    },
    SET_NOTES(state, payload) {
      state.notes = payload
    },
    SET_CURRENT_STATUS(state, payload) {
      state.currentStatus = payload
    },
    SET_NUMBER_SEEN(state, payload) {
      state.numberSeen = payload
    },
    SET_DOSSIER_PROMOTEUR_SELECTED(state, payload) {
      payload.map((terrain) => {
        const idx = state.dossier_promoteur_selected.findIndex(
          (terrain_selected) => terrain_selected.id === terrain.id,
        )
        if (idx >= 0 && !terrain.checked)
          state.dossier_promoteur_selected.splice(idx, 1)
        if (idx === -1 && terrain.checked)
          state.dossier_promoteur_selected.push(terrain)
      })
    },
    RESET_DOSSIER_PROMOTEUR_SELECTED(state) {
      state.dossier_promoteur_selected = []
    },
    SET_SORT_LIST_TERRAINS_BY(state, payload) {
      state.sort_list_terrains_by = payload
    },
    SET_SORT_LIST_TERRAINS_BY_ORDER(state, payload) {
      state.sort_list_terrains_by_order = payload
    },
    SET_DEFAULT_GAMMES(state, payload) {
      state.default_gammes = payload
    },
    SET_DEFAULT_PORJET_BY_PROMY(state, payload) {
      state.default_projet_by_promy = payload
    },
    SET_SHOULD_SEND_MAIL_DESCRIPTION(state, payload) {
      state.description_send_mail = payload
    },
    SET_DESCRIPTIF_PROJET(state, payload) {
      state.descriptif_projet = payload
    },
    setDossierFichiers(state, payload) {
      state.dossier_fichiers = payload
    },
    setDateDebut(state, payload) {
      state.informations.date_debut = payload
    },
  },
  actions: {
    async saveDossierPromoteur({ state }) {
      try {
        await transport.post('admin/dossier-promoteurs', state)
      } catch (e) {
        return e
      }
    },
    async publishDossierPromoteur({ state }, id) {
      try {
        await transport.post(`admin/dossier-promoteurs/publish/${id}`, state)
      } catch (e) {
        return e
      }
    },
    async previewDossierPromoteurPdf({ state }, id) {
      try {
        let response = await transport.get(
          `admin/dossier-promoteurs/${id}/preview-pdf`,
        )
        return response.data
      } catch (e) {
        return e
      }
    },
    async updateDossierPromoteur({ state }, id) {
      try {
        await transport.put(`admin/dossier-promoteurs/${id}`, state)
      } catch (e) {
        return e
      }
    },
    getDossierPromoteur({ commit }, id) {
      return new Promise((resolve) => {
        transport
          .get(`/admin/dossier-promoteurs/${id}`)
          .then((response) => {
            let data = response.data.informations
            data.informations.date_debut = response.data.start_at
            data.informations.date_fin = response.data.end_at
            data.informations.availabe_when_brouillon =
              response.data.availabe_when_brouillon
            data.informations.is_not_visible_when_vendu =
              response.data.is_not_visible_when_vendu

            commit('setInformations', {
              ...data.informations,
              title: response.data.title,
              has_pdf: response.data.has_pdf,
            })
            commit('SET_NUMBER_SEEN', {
              vue_dossier_promoteur: response.data.vue_dossier_promoteur,
              seensBy: response.data.seens_by,
            })
            commit(
              'setConditionSuspensive',
              response.data.conditionsSuspensives,
            )
            commit(
              'SetDossierConditionSuspensive',
              response.data.dossierConditions,
            )
            commit('setCommune', data.commune)
            commit('setContexteGenerale', data.contexte_generale)
            commit('setTimeline', data.timeline)
            commit('setUrbanisme', data.urbanisme)
            commit('setEtudeCommerciale', data.etude_commerciale)
            commit('setProjetByPromy', data.projetbypromy)
            commit('setLocalisation', data.localisation)
            commit('setDossierPhotos', data.dossier_photos)
            commit('setVuesPanoramique', data.vues_panoramique)
            commit('setRapportTechnique', data.rapport_technique)
            commit('setSyntheseDuMarche', data.synthese_marche_immobilier)
            commit('setSyntheseDuMarcheId', response.data.synthese_id)
            commit('setTotalProgress', response.data.total_progress)
            commit('SET_SELECTED_RESPONSABLE', response.data.responsable)
            commit('SET_NOTES', response.data.notes)
            commit('SET_CURRENT_STATUS', response.data.status)
            commit('setDossierFichiers', data.dossier_fichiers)
            commit('SET_DESCRIPTIF_PROJET', data.descriptif_projet)
            commit('setActiveSections', response.data.active_sections)

            resolve(data)
          })
          .catch(() => {
            console.log(
              'un probleme est survenu lors la recuperation dossier promoteur',
            )
          })
      })
    },

    async transferResponsibility({ commit }, params) {
      try {
        let response = await transport.put(
          `admin/dossier-promoteurs/transfer-responsibility/${params.id}`,
          params.responsable,
        )
        return response
      } catch (e) {
        return e
      }
    },
  },
}
